import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import RegenerativePledge from "../components/common/regenerativePledge";
import Description from "../components/biosecurity/description";
import Security from "../components/biosecurity/Security";
import Seo from "../components/seo";
import DownloadLink from "../components/common/DownloadLink";
import Faqs from "../components/home/faq";

const BioSecurity = ({ data: { sanityBioPage, sanitySitesettings } }) => {
  let seo;
  let hero;
  let breadcrumb;
  let regenerative;
  let faq;

  sanityBioPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "regenerative") {
      regenerative = element;
    } else if (element._type === "faq") {
      faq = element;
    }
  });
  return (
    <Layout>
      <Seo data={seo} />
      <Hero heroSection={hero} h2Size="text-3xl sm:text-[60px]" h2P="pb-7" />
      <Logo logo={sanitySitesettings?.logo} />
      {sanityBioPage?._rawIntro && (
        <div className="tb-container py-5 px-[10px] show">
          <div className="w-[90%] mx-auto">
            <Description data={sanityBioPage?._rawIntro} />
          </div>
        </div>
      )}
      {sanityBioPage?.bioSections?.map((item, index) => {
        return <Security data={item} key={index} />;
      })}
      {sanityBioPage?.externalLink && (
        <div className="-mt-10 md:-mt-[100px]">
          <DownloadLink
            text={sanityBioPage?.externalLink?.exText}
            link={sanityBioPage?.externalLink?.url}
          />
        </div>
      )}
            {faq?.heading && faq?.faqList.length > 0 && <Faqs data={faq} />}

      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      <Instagram />
      {regenerative && (
        <>
          <RegenerativePledge data={regenerative} />
          <Logo logo={sanitySitesettings?.logo} />
        </>
      )}
    </Layout>
  );
};

export default BioSecurity;

export const query = graphql`
  query {
    sanityBioPage {
      pageBuilder {
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityFaq {
          _type
          heading
          faqList {
            question
            _rawAnswer
          }
        }
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          alignment
          ctaButton {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityRegenerative {
          _type
          heading
          _rawContent
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
        }
      }
      _rawIntro
      bioSections {
        columns
        heading
        height
        overlay
        svg
        textColor
        bgSize
        bgRepeat
        sectionBG {
          asset {
            url
          }
        }
        columnItem {
          _rawCol
        }
      }
      externalLink {
        exText
        url
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
